import axios from 'axios';
import {getCookie, isAmbienteTest} from "./utils";

let baseUrl;
if (isAmbienteTest()) {
    //per l'ambiente di test, chiamo direttamente laravel
    baseUrl = process.env.REACT_APP_DEV_LARAVEL_REQUEST_URL + ":" + process.env.REACT_APP_DEV_LARAVEL_REQUEST_PORT + "/axios.php";
} else {
    //per l'ambiente reale, passo dal redirector che inoltra la chiamata a Laravel
    baseUrl = process.env.PUBLIC_URL + "/axios.php";
}

export default axios.create({
    baseURL : baseUrl,
    withCredentials : true,
    headers : {
        'Content-Type' : 'application/json',
        'Authorization' : 'Bearer ' + getCookie("token")
    }
});
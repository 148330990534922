import React, {Component} from 'react'
import {BrowserRouter} from 'react-router-dom'
import './scss/style.scss'
import {GuardConfigProvider, GuardedRoute, GuardedRoutes, GuardProvider,} from 'react-router-guarded-routes'
import checkResponse, {axiosPost} from 'src/api/utils';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)


const checkTokenGuard = async (to, from, next, {route}) => {

    try {
        const ret = await axiosPost(
            'check-token'
        );

        const response = checkResponse(ret);

        if (response.success) {
            const token_valid = response.param.token_valido;
            if (!token_valid) {

                //il token non � valido, vado al login (se non ci sono gi�)
                if (to.route.name !== "login") {
                    window.location.replace("/login");
                }
                next.end();
                return;
            } else {

                //se il token � valido, la pagina di login rimanda alla dashboard
                if (to.route.name === "login") {
                    window.location.replace("/dashboard");
                }

            }
        }

    } catch (err) {

    }

    next()
}

const guards = [checkTokenGuard]

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetQR = React.lazy(() => import('./views/pages/resetQR/resetQR'))
const ResetLogin = React.lazy(() => import('./views/pages/resetLogin/resetLogin'))

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <GuardConfigProvider>
                    <GuardProvider fallback={loading}>
                        <GuardedRoutes>
                            <GuardedRoute exact path="/login" name="login" guards={guards} element={<Login/>}/>
                            <GuardedRoute exact path="/404" name="404" element={<Page404/>}/>
                            <GuardedRoute exact path="/500" name="500" element={<Page500/>}/>
                            <GuardedRoute path="/immagini/*" name="immagini"/>
                            <GuardedRoute path="/reset-qr" name="reset_qr" element={<ResetQR/>}/>
                            <GuardedRoute path="/reset-login" name="reset_login" element={<ResetLogin/>}/>
                            <GuardedRoute element={<DefaultLayout/>} path="*" name="home" guards={guards}/>
                        </GuardedRoutes>
                    </GuardProvider>
                </GuardConfigProvider>
            </BrowserRouter>
        )
    }
}

export default App

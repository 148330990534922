import axios from "./axios";

export default function checkResponse(response) {
    let success = false;
    let message = "";
    let param = null;


    if (response.status === 200) {

        response = response.data;


        if (!response.hasOwnProperty('success') || response.success === false) {

            message = "Errore durante la ricezione della risposta.";

            return {
                success : success,
                message : message,
                param : param
            };
        }

        try {
            response = JSON.parse(response.data)
        } catch (e) {
            message = "Errore durante la lettura della risposta.";
            return {
                success : success,
                message : message,
                param : param
            };
        }


        if (
            response.hasOwnProperty('status')
            && response.hasOwnProperty('messaggio')
            && response.hasOwnProperty('parametri')
        ) {

            success = response.status === "OK";
            message = response.messaggio;
            param = response.parametri;
        }

    }

    return {
        success : success,
        message : message,
        param : param
    };
}

export async function postUrl(url, data = null, config = null) {

    let response = null;

    try {

        const ret = await axiosPost(
            url,
            data,
            config
        );

        response = checkResponse(ret);
    } catch (err) {

        if (err.response.status === 401) {
            window.location.replace("/login");
            return;
        }
        console.log(err);
    }

    return response;
}

export async function axiosPost(url, data = null, config = null) {

    if (data !== null) {
        data = JSON.stringify(data);
    }

    if (config === null) {
        config = {};
    }

    //per l'ambiente reale, passo l'url di laravel che voglio chiamare come header al redirector
    if (!config.hasOwnProperty("headers")) {
        config.headers = {};
    }

    let REQUEST_URL = isAmbienteTest() ? process.env.REACT_APP_DEV_LARAVEL_REQUEST_URL : process.env.REACT_APP_PROD_LARAVEL_REQUEST_URL;
    let REQUEST_PORT = isAmbienteTest() ? process.env.REACT_APP_DEV_LARAVEL_REQUEST_PORT : process.env.REACT_APP_PROD_LARAVEL_REQUEST_PORT;

    config.headers["Laravel-Url"] = REQUEST_URL + ":" + REQUEST_PORT + "/" + url;

    url = "";


    return await axios.post(
        url,
        data,
        config
    );

}

export function axiosGet(url, config = null) {

    if (config === null) {
        config = {};
    }

    //per l'ambiente reale, passo l'url di laravel che voglio chiamare come header al redirector
    if (!config.hasOwnProperty("headers")) {
        config.headers = {};
    }

    let REQUEST_URL = isAmbienteTest() ? process.env.REACT_APP_DEV_LARAVEL_REQUEST_URL : process.env.REACT_APP_PROD_LARAVEL_REQUEST_URL;
    let REQUEST_PORT = isAmbienteTest() ? process.env.REACT_APP_DEV_LARAVEL_REQUEST_PORT : process.env.REACT_APP_PROD_LARAVEL_REQUEST_PORT;

    config.headers["Laravel-Url"] = REQUEST_URL + ":" + REQUEST_PORT + "/" + url;

    url = "";

    return axios.get(
        url,
        config
    );

}

export function getCookie(nome_cookie) {
    let cookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith(nome_cookie + '='))
        ?.split('=')[1];

    if (cookie) {
        cookie = decodeURIComponent(cookie);
    }
    return cookie;
}


/**
 * @param {Date|string} data in formato yyyy-mm-dd hh:ii:ss
 */
export function formattaDataPerTable(data) {
    const data_form = (data instanceof Date) ? data : new Date(data);
    const giorno = data_form.getDate().toString().padStart(2, '0');
    const mese = (data_form.getMonth() + 1).toString().padStart(2, '0');
    const anno = data_form.getFullYear();
    const ore = data_form.getHours().toString().padStart(2, '0');
    const minuti = data_form.getMinutes().toString().padStart(2, '0');

    return `${giorno}/${mese}/${anno}, alle ${ore}:${minuti}`;
}

export function isAmbienteTest() {
    return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
}